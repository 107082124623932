.container {
  margin: 0 auto;
  padding: 0 15px;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

h2 span {
  color: #203784;
}

/* Carousel Styles */
.carousel-item img {
  height: 400px;
  object-fit: cover;
  animation: slide-in-left 1s ease-out;
}

.carousel-caption {
  position: absolute;
  bottom: 10%;
  left: 5%;
  right: 5%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  animation: slide-in-bottom 1s ease-out;
}

.carousel-caption .caption-content {
  color: #fff;
}

.carousel-caption h3 {
  font-size: 1.5rem;
  color: #fff;
}

.carousel-caption p {
  font-size: 1rem;
  color: #ddd;
}

/* Services Section */
.card {
  border:#333;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.card-title {
  font-size: 1.25rem;
  color: #203784;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #555;
}

.btn-primary {
  background-color: #203784;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Animations */
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .carousel-item img {
    height: 250px;
  }

  h2 {
    font-size: 2rem;
  }

  .carousel-caption {
    padding: 5px;
  }

  .carousel-caption h3 {
    font-size: 1.25rem;
  }

  .carousel-caption p {
    font-size: 0.875rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .card-text {
    font-size: 0.875rem;
  }
}

@media (max-width: 576px) {
  .carousel-item img {
    height: 200px;
  }

  h2 {
    font-size: 1.75rem;
  }

  .carousel-caption h3 {
    font-size: 1rem;
  }

  .carousel-caption p {
    font-size: 0.75rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.75rem;
  }
}
