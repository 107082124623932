.box {
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.box:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.box-content {
  padding: 20px;
  color: #203784;
}

.image-animation {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.image-animation img {
  transition: transform 0.3s ease-in-out;
}

.image-animation:hover img {
  transform: scale(1.1);
}
li{
  font-size: 18px;
}