h2 {

  font-size: 42px;
  color: #333333;
  padding-bottom: 10px;
  margin-top: 20px;
  font-weight: 900;
  text-align: center;
}

.card {
  background-color: #f8f9fa;
  color: #333333;
  border: none;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
  line-height: 1.5;
}

.card-text a {
  color: #203784;
  text-decoration: none;
  font-weight: bold;
}

.card-text a:hover {
  text-decoration: underline;
}
