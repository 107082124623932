.container {
  max-width: 1200px;
}

.header {
  color: #fff;
}

.header h1 {
  font-size: 2.5rem;
}

.privacy-policy-container {
  padding: 30px;
}

.privacy-section, .team-section {
  margin-bottom: 30px;
}

.privacy-section h2, .team-section h2,
.privacy-section h3, .team-section h3 {
  color: #004d40;
  margin-bottom: 10px;
}

.policy-intro {
  font-size: 1.1rem;
  line-height: 1.8;
}

.privacy-section ul {
  list-style-type: disc;
  margin-left: 20px;
}

.team-section ul {
  list-style-type: circle;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
  
  .header h1 {
    font-size: 2rem;
  }
  
  .privacy-policy-container {
    padding: 15px;
    margin-top: 10px;
  }
  
  .policy-intro {
    font-size: 1rem;
  }
}