/* Styling for About Component */

/* Main container */
.about-container {
  padding-top: 20px;
}

/* Centered h1 */
.about-container h2 {
  font-size: 3rem;
  color: #333;
}

.about-container strong{
  color: #f39c12;
}

/* Text and image alignment */
.about-container p {
  text-align: justify;
}

/* Styling for service boxes */
.service-description {
  padding: 20px;
}

.service-description .box {
  background-color: #f0f0f0; /* Yellow background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-description .box h4 {
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: center;
}

.service-description .box p {
  text-align: justify;
}

/* Responsive image animation */
.image-animation {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-image {
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  /* Adjustments for smaller screens */
  .about-container h1 {
    font-size: 2.5rem;
  }

  .service-description .box h4 {
    font-size: 1.2rem;
  }

  .about-image {
    height: auto;
    width: 100%;
  }
}