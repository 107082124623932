.cookie-acceptance {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333; /* Dark background */
  background-size: cover; /* Ensure background covers the entire container */
  background-repeat: no-repeat; /* Prevent background from repeating */
  color: white; /* Light font color */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
}

.cookie-text {
  margin: 0;
  display: flex;
  color: white;
  align-items: center;
}

.cookie-text button {
  margin-left: 10px;
}