.footer {
  margin-top: auto;
  background-color: #343a40;
  color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 25px 0;
}