.navbar-brand {
  letter-spacing: 5px;
  color: #c24244;
}

.navbar-scroll .nav-link,
.navbar-scroll .fa-bars {
  color: #7f4722;
}

.navbar-scrolled .nav-link,
.navbar-scrolled .fa-bars {
  color: #7f4722;
}

.navbar-scrolled {
  background-color: #f2e8e5;
}
