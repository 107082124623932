.section-title  {
  font-weight: bold;
  text-align: center;
  color: #203784; /* Adjust this to match your theme */
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-body img {
  width: 100%;
  max-width: 180px;
  border-radius: 10px;
}

.btn-primary {
  margin-top: 10px;
}

h2 {
  font-size: 42px;
  color: #333333;
  padding: 0 0 10px;
  margin: 10px 0;
  font-weight: 900;
  text-align: center;
}

li, p{
  color: #333333;
  text-align: justify;
}
span {
  color: #203784;
}